import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import reportWebVitals from './reportWebVitals';
import Personalization from './pages/Personalization';
import EssentialServices from './pages/EssentialServices';
import StepByStepPage from './pages/StepByStepPage'
import PersonalizationPG2 from './pages/PersonalizationPages/PersonalizationPG2';
import PersonalizationPG3 from './pages/PersonalizationPages/PersonalizationPG3';
import PersonalizationPG4 from './pages/PersonalizationPages/PersonalizationPG4';
import PersonalizationPG5 from './pages/PersonalizationPages/PersonalizationPG5';
import PersonalizationPG6 from './pages/PersonalizationPages/PersonalizationPG6';
import PersonalizationPG7 from './pages/PersonalizationPages/PersonalizationPG7';
import PersonalizationPG8 from './pages/PersonalizationPages/PersonalizationPG8';
import OptimizeBudget from './pages/PersonalizationPages/OptimizeBudget';
import OptimizeEffort from './pages/PersonalizationPages/OptimizeEffort';
import MarketPlaceOne from './pages/MarketPlacePages/MarketPlaceOne';
import DetailedPage from './pages/MarketPlacePages/DetailedPage';

import {
  createBrowserRouter,
  RouterProvider, Route,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/step-by-step",
    element: <StepByStepPage />,
  },
  {
    path: "/personalization",
    element: <Personalization />,
  },
  {
    path: "/marketplace",
    element: <MarketPlaceOne />,
  },
  {
    path: "/personalization-two",
    element: <PersonalizationPG2/>
  },
  {
    path: "/personalization-three",
    element: <PersonalizationPG3/>
  },
  {
    path: "/personalization-four",
    element: <PersonalizationPG4/>
  },
  {
    path: "/personalization-five",
    element: <PersonalizationPG5/>
  },
  {
    path: "/personalization-six",
    element: <PersonalizationPG6/>
  },
  {
    path: "/personalization-seven",
    element: <PersonalizationPG7/>
  },
  {
    path: "/personalization-eight",
    element: <PersonalizationPG8/>
  },
  {
    path: "/optimize-for-budget",
    element: <OptimizeBudget/>
  },
  {
    path: "/optimize-for-effort",
    element: <OptimizeEffort/>
  },
  {
    path: "/personalization-one",
    element: <Personalization/>
  },
  {
    path: "/forest-lawn",
    element: <DetailedPage/>
  },

]);

root.render(
  <React.StrictMode>
    <RouterProvider router = {router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
