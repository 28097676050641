import React from 'react'
import DetailedLeft from './DetailedLeft'
import './DetailedPage.css'
import HeaderNavBar from '/Users/liwensun/Desktop/eterna/frontend/src/components/HeaderNavbar.js'
import DetailedRight from './DetailedRight'

function DetailedPage() {
  return (
    <div>
    <HeaderNavBar/>
    <div className="detailed-page">
 
        <DetailedLeft className="left-side"/>
        <div className="vertical-line"></div>
        <DetailedRight className="right-side"/>
        </div>

    </div>
  )
}
export default DetailedPage
