import React from 'react'
import './Questions.css'
import Button from 'react-bootstrap/Button';

function Questions() {
    return (
        <div>
            <div className="question-title">
                <h1 className="detailedpage-titles">Q&A</h1>
                <Button className="review-button">Ask a question</Button>
            </div>
            <p>Q: Do you have to pay to get in?</p>
            <p>A: It's free entry and no you can't get into Michael Jackson's
                building unless your family or friends that have been given permission
                by the family</p>
            <p>Q: Are pets allowed?</p>
            <p>A: Pets are not allowed</p>
            <p style={{color:"rgb(1, 73, 199)"}}>See all questions</p>
        </div>
    )
}

export default Questions
