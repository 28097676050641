import React from 'react'
import './StepByStepPage.css';
import Personalization from './Personalization.js'
import Sidebar from '../components/Sidebar.js';
import StepByStepOne from '../components/StepByStep/StepByStepOne.js';
import StepByStepTwo from '../components/StepByStep/StepByStepTwo.js';
import StepByStepThree from '../components/StepByStep/StepByStepThree.js';
import StepByStepFour from '../components/StepByStep/StepByStepFour.js';
import StepByStepAbout from '../components/StepByStep/StepByStepAbout.js';
import HeaderNavbar from '../components/HeaderNavbar.js';

class PersonalizationPage extends React.Component {
    constructor() {
        super();
        this.state = {step: 0};
        this.toggleStep = this.toggleStep.bind(this); 
    }
    toggleStep(step) {
        this.setState({step});
    }

    render() {
        let CurrentStepComponent;
        switch (this.state.step) {
            case 0:
                CurrentStepComponent = StepByStepAbout;
                break;
            case 1:
                CurrentStepComponent = StepByStepOne;
                break;
            case 2:
                CurrentStepComponent = StepByStepTwo;
                break;
            case 3:
                CurrentStepComponent = StepByStepThree;
                break;
            case 4:
                CurrentStepComponent = StepByStepFour;
                break;
            default:
                CurrentStepComponent = StepByStepAbout;

        }
        return (
            <div>
                <HeaderNavbar/>
                <div className="personalization-container">
                    <Sidebar toggleStep={this.toggleStep}/>
                    <div className="personalization-accordians">
                        <CurrentStepComponent className={`personalization-${this.state.step}`} />
                    </div>
                </div>
            </div>
        );

    }
}

export default PersonalizationPage
