import React from 'react'
import './EffortOtherConsiderations.css'
import IncrementDecrement from './IncrementDecrement'

function EffortOtherConsiderations() {
  return (
    <div className="effort-other-considerations">
      <p>You may also consider:</p>
      <div className="personalization-addtional-considerations">
        <p className="optimize-budget-other-choice">1. Casket: Premium Casket</p>
        <IncrementDecrement intialCount="$25,000"/>
      </div>
      <div className="personalization-addtional-considerations">
        <p>2. Jewelry: Jewelry Keepsakes</p>
        <IncrementDecrement intialCount="$600"/>
      </div>
      <div className="personalization-addtional-considerations">
        <p>3. Memorial Tree: The Gifted Tree</p>
        <IncrementDecrement intialCount="$25"/>
      </div>
    </div>
  )
}

export default EffortOtherConsiderations
