import React from 'react'
import Figure from 'react-bootstrap/Figure';
import OrangeBackground1 from '/Users/liwensun/Desktop/eterna/frontend/src/images/brown-background1.png'
import OrangeBackground2 from '/Users/liwensun/Desktop/eterna/frontend/src/images/brown-background2.png'
import OrangeBackground3 from '/Users/liwensun/Desktop/eterna/frontend/src/images/brown-background3.png'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';



import './Reviews.css'
function Reviews() {
    return (
        <div>
            <p className="services-header">Voice of Customers</p>
            <Carousel>
                <Carousel.Item>
                <Carousel.Caption>
                    </Carousel.Caption>
                    <img
                        className="d-block w-100"
                        src={OrangeBackground1}
                        alt="First slide"
                    />
                    
                </Carousel.Item>

                <Carousel.Item>
                <Carousel.Caption>
                    </Carousel.Caption>
                    <img
                        className="d-block w-100"
                        src={OrangeBackground2}
                        alt="Second slide"
                    />
                  
                </Carousel.Item>

                <Carousel.Item>
                <Carousel.Caption>
                    </Carousel.Caption>
                    <img
                        className="d-block w-100"
                        src={OrangeBackground3}
                        alt="Third slide"
                    />
                    
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default Reviews
