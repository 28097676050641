import React from 'react'
import Card from 'react-bootstrap/Card';
import './DetailedPricing.css'


function DetailedPricing() {
    return (
        <div>
            <h1 className="detailedpage-titles">Pricing</h1>
            <Card style={{ width: '26rem' }}>
                <Card.Body>
                    <Card.Title>FUNERAL AND MEMORIAL SERVICES</Card.Title>
                    <div className="a-line">
                        <Card.Text>Basic services and facilities</Card.Text>
                        <Card.Text>$2,400</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Transfer</Card.Text>
                        <Card.Text>$700</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Enbalming</Card.Text>
                        <Card.Text>$775</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Care and preparation</Card.Text>
                        <Card.Text>$475</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Visiting</Card.Text>
                        <Card.Text>not included</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Graveside ceremony</Card.Text>
                        <Card.Text>$700</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Casket coach</Card.Text>
                        <Card.Text>$375</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Casket (choice)</Card.Text>
                        <Card.Text>$2,700</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Floral tribute</Card.Text>
                        <Card.Text>$700</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Stationary products</Card.Text>
                        <Card.Text>$150</Card.Text>
                    </div>
                    <Card.Text>Memorial book, standard memory folders (100).</Card.Text>
                    <Card.Text>acknowledgement cards (50).</Card.Text>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text className="detailedpg-bold">Total Funeral and Memorial Services</Card.Text>
                        <Card.Text className="detailedpg-bold">$8,975</Card.Text>
                    </div>

                    <hr className="marketplace-gold-hr"/>

                    <Card.Title>CEMETERY SERVICES</Card.Title>
                    <div className="a-line">
                        <Card.Text>Internet and recording (burial)</Card.Text>
                        <Card.Text>$2,300</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Other burial container (Duravault)</Card.Text>
                        <Card.Text>$1,200</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Standard bronze memorial tablet (16"*28")</Card.Text>
                        <Card.Text>$1,600</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Placement and foundation</Card.Text>
                        <Card.Text>$525</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Single-vase service</Card.Text>
                        <Card.Text>$75</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Carnation boutonnieres (6)</Card.Text>
                        <Card.Text>included</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text className="detailedpg-bold">Total Cemetery Services</Card.Text>
                        <Card.Text className="detailedpg-bold">$5,700</Card.Text>
                    </div>
                    <hr className="marketplace-gold-hr"/>

                    <div className="a-line">
                        <Card.Text >Total Package Value</Card.Text>
                        <Card.Text>$14,675</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text>Casket Discoount</Card.Text>
                        <Card.Text>-$1,250</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                    <div className="a-line">
                        <Card.Text className="detailedpg-bold">PACKAGE PRICE</Card.Text>
                        <Card.Text className="detailedpg-bold">$13,425</Card.Text>
                    </div>
                    <hr className="marketplace-standard-hr"/>
                </Card.Body>
            </Card>
            <hr className="forest-lawn-line"/>
        </div>
    )
}

export default DetailedPricing
