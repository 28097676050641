import React from 'react'
import './PersonalizationOne.css'
import ListGroup from 'react-bootstrap/ListGroup';
import withNavigate from './Navigate'; 
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';



class PersonalizationSix extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: null};
  }

  handleItemClick = (index) => {
    this.setState({ activeIndex: index });
  };

  handleNextClick = () => {
    this.props.navigate('/personalization-seven');
  };

  handlePrevClick = () => {
    this.props.navigate(-1);
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <h1 className="personalization-header">What is the estimated amount of friends and family attending?</h1>
        <Form.Control className="personalization-textbox" type="text" placeholder="(Number)" />
        <Button className="next-button" onClick={this.handlePrevClick}>Back</Button>
        <Button className="next-button" onClick={this.handleNextClick}>Next</Button>
      </div>
    );
  }
}


export default withNavigate(PersonalizationSix);
