import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import './MarketDropdowns.css'

function MarketDropdowns() {
    return (
        <div>
            <div className="marketplace-buttons">
            <Dropdown className="marketplace-dropdowns">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="marketplace-menu-dropdowns">
                    Funeral Services
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Funeral Homes</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Cremation</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Crematory</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Mortuary</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Additional Service</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="marketplace-dropdowns">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="marketplace-menu-dropdowns">
                    Funeral Products
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Caskets</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Urns</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Shrouds</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Headstones</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Jewelry</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="marketplace-dropdowns">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="marketplace-menu-dropdowns">
                    Probate
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Estate sale</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Apprasiers</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Attorney</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="marketplace-dropdowns">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="marketplace-menu-dropdowns">
                    Finance
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Accountants</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Insurance</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Pre-need</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="marketplace-dropdowns">
                <Dropdown.Toggle variant="primary" id="dropdown-basic" className="marketplace-menu-dropdowns">
                    Grief Support
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Grief therapists</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">End of life data</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Grief support groups</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Psychiatrists</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </div>

        </div>
    )
}

export default MarketDropdowns
