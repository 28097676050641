import React from 'react'
import './DetailedPageAbout.css'
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { BsStarFill } from 'react-icons/bs';
import StarsSharpIcon from '@mui/icons-material/StarsSharp';


function DetailedPageAbout() {
  return (
    <div>
      <hr className="first-forest-lawn-line"/>
      <h1 className="forestlawn-titles">Forest Lawn</h1>
      <div className="detailedpage-firstline">
        <StarsSharpIcon style={{ color: 'orange' }} />
        <StarsSharpIcon style={{ color: 'orange' }} />
        <StarsSharpIcon style={{ color: 'orange' }} />
        <StarsSharpIcon style={{ color: 'orange' }} />
        <StarsSharpIcon style={{ color: 'lightgrey' }} />
        <p className="star-number">4.0 (204 reviews)</p>
  
        <p className="five">5.0 </p>
        <BsStarFill className="star" color="gold" />
        <BsStarFill color="gold" />
        <BsStarFill color="gold" />
        <BsStarFill color="gold" />
        <BsStarFill color="gold" />
        <p className="google-reviews"> 658 Google reviews</p>

      </div>
      <hr className="forest-lawn-line"/>
      <h1 className="detailedpage-titles">About</h1>
      <div className="icons">
        <div className="address-phone">
          <p><BusinessRoundedIcon /> Address: 1712 S Glendale Ave Glendale, CA 91205</p>
          <p><LocalPhoneOutlinedIcon /> Phone: (888) 204-3131</p>
        </div>
        <div className="website-hours">
          <p><LanguageRoundedIcon /> Website: forestlawn.com</p>
          <p><QueryBuilderOutlinedIcon /> Hours: Mon-Sun 8:00 am - 6:00 pm</p>
        </div>
      </div>
      <p className="overview">Overview: Forest Lawn has been a trusted funeral service
        provider in Southern California with locations across Los Angeles,
        Orange and Riverside counties. We offer the convenience of having
        mortuary, cremation, memorial services, flowers, and cemetery property
        all in one place</p>
      <p className="highlight">Highlight: Variety of packages with different budget options,
        Beautiful </p>
      <hr className="forest-lawn-line" />
    </div>
  )
}

export default DetailedPageAbout

