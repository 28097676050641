import React from 'react'
import Form from 'react-bootstrap/Form';
import HeaderNavbar from '../../components/HeaderNavbar';
import MarketDropdowns from '../../components/MarketPlace/MarketDropdowns';
import TopFuneralHomes from '../../components/MarketPlace/TopFuneralHomes';
import Image from 'react-bootstrap/Image';
import Figure from 'react-bootstrap/Figure';
import map from '/Users/liwensun/Desktop/eterna/frontend/src/images/map.png'
import './MarketPlaceOne.css'
import Filter from '../../components/MarketPlace/Filter';
import Button from 'react-bootstrap/Button';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';


function MarketPlaceOne() {
  return (
    <div>
      <HeaderNavbar />
      <div className="marketplace-searchbar">
        <Form.Control
          type="text"
          placeholder="Forest Lawns"
          className=" mr-sm-1" />
        <Form.Control
          type="text"
          placeholder="Los Angeles"
          className=" mr-sm-2" />
          <Button className="searchbar-button"><SearchSharpIcon/></Button>
      </div>
      <MarketDropdowns />
      <hr/>
      <div className="funeralhomes-and-banner">
        <Filter />
        <div className="funeral-homes">
          <TopFuneralHomes />
        </div>
        <Figure className="map">
          <Figure.Image
            width={400}
            height={800}
            alt="171x180"
            src={map}
          />
        </Figure>

      </div>
    </div>
  )
}

export default MarketPlaceOne
