import React from 'react'
import './PersonalizationOne.css'
import ListGroup from 'react-bootstrap/ListGroup';
import withNavigate from './Navigate'; 
import Button from 'react-bootstrap/Button';

class PersonalizationOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {activeIndex: null};
  }

  handleItemClick = (index) => {
    this.setState({ activeIndex: index });
  };

  handleNextClick = () => {
    this.props.navigate('/personalization-two');
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <h1 className="personalization-header">How can we help you?</h1>
        <ListGroup as="ul">
          <ListGroup.Item
            as="li"
            active={activeIndex === 0}
            onClick={() => this.handleItemClick(0)}
            className="personalization-listgroup"
          >
            Someone has passed
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndex === 1}
            onClick={() => this.handleItemClick(1)}
            className="personalization-listgroup"
          >
            Planning ahead for myself or someone else
          </ListGroup.Item>
        </ListGroup>
        <Button className="next-button" variant="primary" onClick={this.handleNextClick}>Next</Button>
      </div>
    );
  }
}


export default withNavigate(PersonalizationOne);
