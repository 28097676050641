import React from 'react'
import './PersonalizationOne.css'
import ListGroup from 'react-bootstrap/ListGroup';
import withNavigate from './Navigate';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


class PersonalizationEight extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: null };
  }

  handleItemClick = (index) => {
    this.setState({ activeIndex: index });
  };

  handleNextClick = () => {
    const { activeIndex } = this.state;
    if (activeIndex === 0) {
      this.props.navigate('/optimize-for-budget');
    }
    else if (activeIndex === 1) {
      this.props.navigate('/optimize-for-effort');
    }
  };

  handlePrevClick = () => {
    this.props.navigate(-1);
  };



  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <h1 className="personalization-header">What are you trying to optimize the most?</h1>
        <div className="personalization-cards">
          <Card style={{ width: '16rem', height: '16rem' }} onClick={() => this.handleItemClick(0)}
            className={`personalization-card ${activeIndex === 0 ? 'active' : ''}`}>
            <Card.Body>
              <Card.Text className="personalization-card-text">
                Optimize for budget
              </Card.Text>
            </Card.Body>
          </Card>

          <Card style={{ width: '16rem' }} onClick={() => this.handleItemClick(1)}
            className={`personalization-card ${activeIndex === 1 ? 'active' : ''}`}>
            <Card.Body>
              <Card.Text className="personalization-card-text">
                Optimize for effort
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <Button className="next1-button" onClick={this.handlePrevClick}>Prev</Button>
        <Button className="next1-button" onClick={this.handleNextClick}>Next</Button>
      </div>
    );
  }
}


export default withNavigate(PersonalizationEight);
