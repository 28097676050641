import React from 'react'
import './StepByStepAbout.css'
import HeaderNavbar from '/Users/liwensun/Desktop/eterna/frontend/src/components/HeaderNavbar.js'
import Figure from 'react-bootstrap/Figure';
import holdingHands from '/Users/liwensun/Desktop/eterna/frontend/src/images/holding-hands.png';
function StepByStepAbout() {
  return (
    <div>
      <h1 className="step-by-step-title">Step-By-Step Guide</h1>
      <p className="step-by-step-description">This step-by-step guide is designed to assist families in managing 
        the affairs of a loved one who has passed away. We've organized the 
        tasks by timeline to help you prioritize what needs immediate attention 
        and what can be addressed later. Create an account to save your 
        progress as you complete each task, and share it with family members. 
</p>
<Figure className="holding-hands">
        <Figure.Image
          width={440}
          height={800}
          alt="171x180"
          src={holdingHands}
        />
      </Figure>
    </div>
  )
}

export default StepByStepAbout
