import React from 'react'
import './PersonalizationOne.css'
import ListGroup from 'react-bootstrap/ListGroup';
import withNavigate from './Navigate';
import Button from 'react-bootstrap/Button';


class PersonalizationTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndices: [] };
  }

  handleItemClick = (index) => {
    this.setState((prevState) => {
      let activeIndices;
      if (prevState.activeIndices.includes(index)) {
        activeIndices = prevState.activeIndices.filter(i => i !== index);
      } else {
        activeIndices = [...prevState.activeIndices, index];
      }
      return { activeIndices };
    });
  };

  handleNextClick = () => {
    const { activeIndices } = this.state;
    const nextPages = [];
    if (activeIndices.includes(0)) {
      nextPages.push('personalization-three');
    }
    if (activeIndices.includes(1)) {
      nextPages.push('personalization-five');
    }
    if (activeIndices.includes(2) || activeIndices.includes(3)) {
      nextPages.push('personalization-six');
    }
    if (activeIndices.includes(4)) {
      nextPages.push('personalization-seven');
    }
    this.props.navigate(`/${nextPages.shift()}`, { state: { nextPages } });
  };

  handlePrevClick = () => {
    this.props.navigate(-1);
  };

  render() {
    const { activeIndices } = this.state;

    return (
      <div>
        <h1 className="personalization-header">What kind of service(s) you may be interested in?</h1>
        <ListGroup as="ul">
          <ListGroup.Item
            as="li"
            active={activeIndices.includes(0)}
            onClick={() => this.handleItemClick(0)}
            className="personalization-listgroup"
          >
            Cremation
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndices.includes(1)}
            onClick={() => this.handleItemClick(1)}
            className="personalization-listgroup"
          >
            Burial
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndices.includes(2)}
            onClick={() => this.handleItemClick(2)}
            className="personalization-listgroup"
          >
            Funeral Service
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndices.includes(3)}
            onClick={() => this.handleItemClick(3)}
            className="personalization-listgroup"
          >
            Memorial Service
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndices.includes(4)}
            onClick={() => this.handleItemClick(4)}
            className="personalization-listgroup"
          >
            Not sure
          </ListGroup.Item>
        </ListGroup>
        <Button className="next-button" onClick={this.handlePrevClick}>Prev</Button>
        <Button className="next-button" onClick={this.handleNextClick}>Next</Button>
      </div>
    );
  }
}


export default withNavigate(PersonalizationTwo);
