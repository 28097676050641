import React from 'react'
import RationaleBlock from '../../components/Personalization/RationaleBlock'
import TotalPrice from '../../components/Personalization/TotalPrice'
import EffortOtherConsiderations from '../../components/Personalization/EffortOtherConsiderations'
import './OptimizeForEffort.css'


function OptimizeForEffort() {
  return (
    <div>
      <div className="optimize-for-budget">
        <h1 className="optimize-budget-title">Optimize for Effort</h1>
        <div className="price-and-rationale">
        <TotalPrice price="13,425" />
        <RationaleBlock title="Rationale" content="Forest Lawn offers 
      all-inclusive packages that cover everything, including personalized 
      funeral or memorial services, Visitation and Viewing Services, 
      Embalming and Body Preparation, etc. This holistic approach not only 
      minimizes logistical burdens but also provides families with a sense 
      of reassurance and support as they navigate their loss"/>
      </div>
        <div className="budget-price-and-rationale">
          <EffortOtherConsiderations />
          <RationaleBlock title="Rationale" content="While comprehensive funeral
       homes like Forest Lawn offer a range of services, families may have 
       specific preferences that aren't fully covered. For example, opting 
       for a premium casket, jewelry keepsakes or memorial tree can serve 
       as meaningful mementos for family members"/>
        </div>
      </div>
    </div>
  )
}

export default OptimizeForEffort
