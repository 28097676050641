import React from 'react'
import './StepByStepOne.css'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import SavePlan from './SavePlan';


function StepByStepFour(props) {
  const allEventKeys = ["11", "12"];
  const [activeKeys, setActiveKeys] = useState(allEventKeys);
  return (
    <div className={props.className}>
      <div className="steps-title">
        <h1 className="step-by-step-title">Handle These Fourth</h1>
        <SavePlan />
      </div>
      <Accordion className="steps-accordian" defaultActiveKey={activeKeys} alwaysOpen={true}>
        <Accordion.Item eventKey="11">
          <Accordion.Header>Handling Dependents and Assets</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`File any outstanding claims for health insurance and medicare`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Pay off outstanding debts, credit balance bills, and loans`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Estate sale`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12">
          <Accordion.Header>Outreach and Inform Others</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Contact professional service providers (e.g., lawyers,
                    accountants, financial advisors)`}
                    className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify cedit bureaus, DMV, Registar of Voters`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Organize and distribute deceased's personal belongings`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default StepByStepFour
