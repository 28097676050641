import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/esm/Button';
import { BsStarFill } from 'react-icons/bs';
import Form from 'react-bootstrap/Form';
import './Filter.css'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';


function Filter() {
    return (
        <div>
            <Card className="text-center">
                <Card.Header className="filter-header">Filter</Card.Header>
                <Card.Body>
                    <Form.Group>
                        <div className="message-title-container">
                            <Form.Label className="price-title">Price</Form.Label>
                        </div>
                        <div className="price-selects">
                            <Button className="price-button1">$</Button>
                            <Button className="price-button">$$</Button>
                            <Button className="price-button">$$$</Button>
                            <Button className="price-button4">$$$$</Button>
                        </div>
                    </Form.Group>
                    <div className="message-title-container">
                            <Form.Label className="review-title">Reviews (Number of Stars)</Form.Label>
                        </div>
                        <div className="review-selects">
                            <Button className="review-button1">1</Button>
                            <Button className="price-button2">2</Button>
                            <Button className="price-button2">3</Button>
                            <Button className="price-button2">4</Button>
                            <Button className="review-button4">5</Button>
                        </div>
                </Card.Body>
            </Card>

        </div>
    )
}

export default Filter
