import React from 'react'
import RationaleBlock from '../../components/Personalization/RationaleBlock'
import IncrementDecrement from '../../components/Personalization/IncrementDecrement'
import TotalPrice from '../../components/Personalization/TotalPrice'
import './OptimizeForBudget.css'
import HeaderNavbar from '../../components/HeaderNavbar'
import OtherConsiderations from '../../components/Personalization/OtherConsiderations'
import TotalPriceAndRationale from '../../components/Personalization/TotalPriceAndRationale'

function OptimizeForBudget() {
    return (
        <div>
            
            <div className="optimize-for-budget">
                <h1 className="optimize-budget-title">Optimize for budget</h1>
                <TotalPriceAndRationale />
                <div className="budget-price-and-rationale">
                    <OtherConsiderations/>
                    <RationaleBlock className="rationale-block-1" title="Rationale" content="Forest Lawn is known 
        for its beautiful grounds and comprehensive funeral services. They 
        offer all-inclusive packages that cover everything, including 
        personalized funeral or memorial services, visitation, Visitation 
        and Viewing Services, Embalming and Body Preparation, etc. This 
        holistic approach not only minimizes logistical burdens but also 
        provides families with a sense of reassurance and support as they 
        navigate their loss"/>
                </div>
            </div>
        </div>
    )
}

export default OptimizeForBudget
