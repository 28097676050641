import React from 'react'
import Services from '../components/Services'
import './EssentialServices.css';
import Figure from 'react-bootstrap/Figure';
import service1 from '../images/service1.png'
import service2 from '../images/service2.png'
import service3 from '../images/service3.png'
import border from '../images/homepage-border.png'
import coffin from '../images/coffin.jpg'


function EssentialServices() {
    return (
        <div className="essential-services" id="about">
            <div >
                <p className="services-header">Streamlining Essential Services</p>

                <div className="essential-services-body">
                 

                    <div className="service1-container">
                        <Services title="Step-by-Step Guide" body="Offering a comprehensive end-of-life checklist designed to meet
                        your family's urgent needs and help you face the challenges of loss" hyperlink="hyperlink" />
                        <Figure className="desert-placeholder">
                            <Figure.Image
                                width={500}
                                height={800}
                                alt="171x180"
                                src={service3}
                            />
                        </Figure>
                    </div>

                    <div className="service2-container">
                        <Figure className="desert2-placeholder">
                            <Figure.Image
                                width={500}
                                height={800}
                                alt="171x180"
                                src={service2}
                            />
                        </Figure>
                        <p>
                            <Services title="Comprehensive Marketplace Solutions"
                                body="Discovering a one-stop marketplace where families could find all necessary
                        end-of-life services"
                            />
                        </p>
                    </div>
                    <div className="service3-container">
                        <Services title="Personalized Guide" body="Accessing personalized end-of-life
            service and vendor recommendations with transparent pricing to reduce stress and saving time" hyperlink="hyperlink" />
                        <Figure className="desert-placeholder">
                            <Figure.Image
                                width={500}
                                height={800}
                                alt="171x180"
                                src={service1}
                            />
                        </Figure>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EssentialServices
