import React from 'react'
import './Photos.css'
import Figure from 'react-bootstrap/Figure';
import photos1 from '/Users/liwensun/Desktop/eterna/frontend/src/images/photos1.png';
import photos2 from '/Users/liwensun/Desktop/eterna/frontend/src/images/photos2.png';
import photos7 from '/Users/liwensun/Desktop/eterna/frontend/src/images/photos7.png';
import photos8 from '/Users/liwensun/Desktop/eterna/frontend/src/images/photos8.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

function Photos() {
  return (
    <div>
      <h1 className="detailedpage-titles">Photos</h1>
      <Figure className="marketplace-photo">
        <Figure.Image
          width={200}
          height={800}
          alt="171x180"
          src={photos1}
        />
      </Figure>
      <Figure className="marketplace-photo">
        <Figure.Image
          width={200}
          height={800}
          alt="171x180"
          src={photos2}
        />
      </Figure>
      <Figure className="marketplace-photo">
        <Figure.Image
          width={200}
          height={800}
          alt="171x180"
          src={photos7}
        />
      </Figure>
      <Figure className="marketplace-photo">
        <Figure.Image
          width={200}
          height={800}
          alt="171x180"
          src={photos8}
        />
      </Figure>
      <ArrowForwardIosIcon className="images-arrow" style={{ fontSize: 50 }}/>
      <hr className="forest-lawn-line"/>
    </div>
  )
}

export default Photos
