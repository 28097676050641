import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import './StepByStepOne.css'
import { useState } from 'react';
import SavePlan from './SavePlan';

function StepByStepThree(props) {
  const allEventKeys = ["7", "8", "9", "10"];
  const [activeKeys, setActiveKeys] = useState(allEventKeys);
  return (
    <div className={props.className}>
      <div className="steps-title">
        <h1 className="step-by-step-title">Handle These Third</h1>
        <SavePlan className="steps-save-plan" />
      </div>
      <Accordion className="steps-accordian" defaultActiveKey={activeKeys} alwaysOpen={true}>
        <Accordion.Item eventKey="7">
          <Accordion.Header>Handling Dependents and Assets</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Cancel all the accounts (e.g., utility, bank, social,
                    insutance, subscription)`}
                    className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>Notify Local Authorities</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify Post Office and set up mail forwarding`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>Execute the Will</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Consult with an attorney about probate`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>Navigate Grief</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Grief (e.g., join a grief group, find a grief therapy)`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default StepByStepThree
