import React from 'react'
import OptimizeForEffort from './OptimizeForEffort'
import './OptimizeEffort.css'
import HeaderNavbar from '../../components/HeaderNavbar'
import Button from 'react-bootstrap/esm/Button'
import SaveMyPlan from '../../components/Personalization/SaveMyPlan'

function OptimizeEffort() {
  return (
    <div>
      <HeaderNavbar />
      <div className="optimize-effort">
        <OptimizeForEffort />
      </div>
      <div className="save-my-plan">
        <SaveMyPlan />
      </div>

    </div>
  )
}

export default OptimizeEffort
