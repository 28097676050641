import React from 'react'
import Card from 'react-bootstrap/Card';
import { BsStarFill } from 'react-icons/bs';
import './TopFuneralHomes.css'

function TopFuneralHomes() { 
  return ( 
    <div>
      <p className="top-10">Top 10 Best Cemeteries Near Los Angeles, California</p>
      <p className="sponsored-results">Sponsored Results</p>
      <a href="/forest-lawn" className="card-link">
        <Card>
          <Card.Body>
            <Card.Title>Forest Lawn</Card.Title>
            <div className='reviews'>
              <BsStarFill color="gold" />
              <BsStarFill color="gold" />
              <BsStarFill color="gold" />
              <BsStarFill color="gold" />
              <BsStarFill color="grey" />
              <p>4.0 (202 Reviews)</p>
            </div>
            <p>1712 S Glendale Ave, Glassel Park</p>

            <Card.Text>
              "Forest Lawn has been a trusted funeral service Provider in Southern
              California. With locations across Los Angeles, Orange and Riverside counties,
              we offer the convience of having mortuary, cremation, memorial services, flowers,
              and cemetary property all in one place"
            </Card.Text>
          </Card.Body>
        </Card>
      </a>
      <p>All Funeral Homes in Los Angeles</p>
      <Card>
        <Card.Body>
          <Card.Title>Optima Funeral Home</Card.Title>
          <div className='reviews'>
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <p>5.0 (30 Reviews)</p>
          </div>
          <p>4901 Compton Ave, Central Alameda</p>
          <Card.Text>
            "We are a family owned, full-service funeral home in the heart of Los
            Angeles. We serve families of all faiths, nationalities and beliefs from
            many different countries"
          </Card.Text>
        </Card.Body>
      </Card>
      <Card >
        <Card.Body>
          <Card.Title>Natural Grace Funerals and Cremations</Card.Title>
          <div className='reviews'>
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <BsStarFill color="gold" />
            <p>5.0 (202 Reviews)</p>
          </div>
          <p>11777 San Vicente Blvd, Brentwood</p>
          <Card.Text>
            "Natural Grace offers all traditional Funeral services desired by families along with
            offering more natural options to include Home Funerals, Green Burrials,
            Acqua Cremation and support for the process of Human Composting"
          </Card.Text>
        </Card.Body>
      </Card>

    </div>
  )
}

export default TopFuneralHomes
