import React from 'react'
import './SaveMyPlan.css'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
function SaveMyPlan() {
    const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [showEmailMessage, setShowEmailMessage] = useState(false);

  const handleCloseCreateAccount = () => setShowCreateAccount(false);
  const handleShowCreateAccount = () => setShowCreateAccount(true);

  const handleCloseEmailMessage = () => setShowEmailMessage(false);
  const handleShowEmailMessage = () => {
    setShowCreateAccount(false); // Close create account modal
    setShowEmailMessage(true);
  };

  return (
    <div className="save-my-plan">
      <Button className="personalization-save-button" onClick={handleShowCreateAccount}>
        Save my plan
      </Button>
      
      {/* Modal for Create Account */}
      <Modal show={showCreateAccount} onHide={handleCloseCreateAccount}>
        <Modal.Header closeButton>
          <Modal.Title>Create Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Form.Group controlId="formConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>

            <Button variant="danger" className="w-100 my-2">
              Continue with Google
            </Button>
            <Button variant="primary" className="w-100 my-2">
              Continue with Facebook
            </Button>

            <Button variant="primary"  onClick={handleShowEmailMessage}>
              Create Account
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for Empty Email Message */}
      <Modal show={showEmailMessage} onHide={handleCloseEmailMessage} dialogClassName="compose-modal wide-modal">
        <Modal.Header closeButton className="compose-modal-header">
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formEmail">
              <Form.Control type="email" defaultValue={"To: Tulip Cremation"} className="compose-input" />
            </Form.Group>
            <Form.Group controlId="formSubject">
              <Form.Control type="text" defaultValue={"Subject: Confirmation of Cremation Services"} className="compose-input" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Control as="textarea" rows={18} defaultValue={`Dear Tulip Cremation Team,

I am writing to confirm the arrangements for my grandmother’s cremation. I have selected your cremation package and the additional services needed. Here are the details:

Please find the details of the selected services below:

Cremation Package: Basic cremation package
Additional Services: memorial service for 50~100 people, urn

Could you please confirm the next steps and provide any necessary paperwork or details? Additionally, I would appreciate information on the expected timeline and any specific instructions I should follow.
Thank you for your assistance during this difficult time. Please let me know if you need any further information from my side.

Sincerely,
Bob`} className="compose-input" />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEmailMessage}>
            Draft
          </Button>
          <Button variant="primary" onClick={handleCloseEmailMessage}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
    
}

export default SaveMyPlan
