import React from 'react'
import './ContactBlock.css'

function ContactBlock(props) {
  return (
    <div>
      <h1 className="contact-title">{props.title}</h1>
      <p className="contact-body">{props.body}</p>
    </div>
  )
}

export default ContactBlock
