import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import PersonalizationOne from '../components/Personalization/PersonalizationOne';
import './personalization.css'
import Figure from 'react-bootstrap/Figure';
import flowerBackground from '../images/flowers.png';
import HeaderNavbar from '../components/HeaderNavbar'; 



function Personalization() {
  return (
    <div>
      <HeaderNavbar/>
      <div className="pers-pg1-container">
        <Figure className="flower-background">
          <Figure.Image
            width={500}
            height={800}
            margin={0}
            alt="171x180"
            src={flowerBackground}
          />
        </Figure>
        <div className="pers-1">
          <PersonalizationOne />
        </div> 
      </div>
    </div>
  ) 
}
export default Personalization
