import React from 'react'
import './ConnectDescription.css';
import MessageUs from './MessageUs.js'

function ConnectDescription(props) {
  return (
    <div>
      <div className="connect-description-container">
        <h1 className="connect-title">{props.title}</h1>
        <p className="connect-body">{props.body}</p>
      </div>

    </div>
  )
}

export default ConnectDescription
