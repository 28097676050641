import React from 'react'
import './DetailedRight.css'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Calendar from '/Users/liwensun/Desktop/eterna/frontend/src/images/calendar.png'
import { useState } from 'react';
import Figure from 'react-bootstrap/Figure';


function DetailedRight() {
  const [showImage, setShowImage] = useState(false);

  const handleShowImage = () => {
    setShowImage(true);
  };

  const handleCloseImage = () => {
    setShowImage(false);
  };

  return (
    <div className="right-side">



      <p className="rightside-words">Request Consultation</p>
      <Button className="rightside-button" onClick={handleShowImage}>Schedule now</Button>
      {showImage && (
        <div className="image-container" onClick={handleCloseImage}>
          <Figure className="calendar">
            <Figure.Image
              width={500}
              height={800}
              alt="171x180"
              src={Calendar}
            />
          </Figure>
        </div>
      )}


    </div>
  )
}

export default DetailedRight
