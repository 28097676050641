import React from 'react'
import OptimizeForBudget from './OptimizeForBudget'
import './OptimizeBudget.css'
import HeaderNavbar from '../../components/HeaderNavbar'
import SaveMyPlan from '../../components/Personalization/SaveMyPlan'

function OptimizeBudget() {
  return (
    <div>
      <HeaderNavbar />
      <div className="optimize-budget">
        <OptimizeForBudget />
      </div>
      <div className="save-my-plan">
        <SaveMyPlan />
      </div>
    </div>
  )
}

export default OptimizeBudget
