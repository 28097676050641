import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import './StepByStepOne.css'
import { useState } from 'react';
import SavePlan from './SavePlan';


function StepByStepOne(props) {
  const allEventKeys = ["0", "1", "2", "3"];
  const [activeKeys, setActiveKeys] = useState(allEventKeys);


  return (
    <div className={props.className}>
      <div className="steps-title">
        <h1 className="step-by-step-title">Handle These First</h1>
        <SavePlan/>
      </div>
      <Accordion className="steps-accordian" defaultActiveKey={activeKeys} alwaysOpen={true}>
        <Accordion.Item eventKey="0">
          <Accordion.Header >Handling Dependents and Assets</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Set up care for any dependents or pets`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Freeze all active credit cards`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Secure homes and cars`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Notify Local Authorities</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Get a legal pronouncement of death (reporting of death)`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Order death certificate with state and county`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify the SSN`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Secure Key Documents and Valuables</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Locate important documents (e.g., will, birth certificate,
                    SSN, marriage license, deed, life insurance, funeral pre-arrangements)`}
                    className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Execute the Will</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify the executor of the will`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default StepByStepOne
