import React from 'react'
import './Services.css'

function Services(props) {
  return (
    <div>
      <h1 className="services-title">{props.title}</h1>
      <p className="services-body">{props.body}</p>


    
    </div>
  )
}

export default Services
