import React from 'react'
import './PersonalizationOne.css'
import ListGroup from 'react-bootstrap/ListGroup';
import withNavigate from './Navigate';
import Button from 'react-bootstrap/Button';


class PersonalizationFour extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: null };
  }

  handleItemClick = (index) => {
    this.setState({ activeIndex: index });
  };

  handleNextClick = () => {
    const state = this.props.location.state;
    console.log(state);
    if (state.nextPages && state.nextPages.length) {
      this.props.navigate(`/${state.nextPages.shift()}`, {state});
    } else {
      this.props.navigate('/personalization-seven');
    }
  };

  handlePrevClick = () => {
    this.props.navigate(-1);
  };

  render() {
    const { activeIndex } = this.state;

    return (
      <div>
        <h1 className="personalization-header">What is your preferred method for handling the cremated remains?</h1>
        <ListGroup as="ul">
          <ListGroup.Item
            as="li"
            active={activeIndex === 0}
            onClick={() => this.handleItemClick(0)}
            className="personalization-listgroup"
          >
            Urn
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndex === 1}
            onClick={() => this.handleItemClick(1)}
            className="personalization-listgroup"
          >
            Scattering
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndex === 2}
            onClick={() => this.handleItemClick(2)}
            className="personalization-listgroup"
          >
            Interment
          </ListGroup.Item>
          <ListGroup.Item
            as="li"
            active={activeIndex === 3}
            onClick={() => this.handleItemClick(3)}
            className="personalization-listgroup"
          >
            Not sure
          </ListGroup.Item>
        </ListGroup>
        <Button className="next-button" onClick={this.handlePrevClick}>Prev</Button>
        <Button className="next-button" onClick={this.handleNextClick}>Next</Button>
      </div>
    );
  }
}


export default withNavigate(PersonalizationFour);
