import React from 'react'
import './IncrementDecrement.css'
import Button from 'react-bootstrap/Button';

class IncrementDecrement extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        count: props.intialCount
      };
    }
  
    handleIncrement = () => {
      this.setState((prevState) => ({
        count: prevState.count + 1
      }));
    };
  
    handleDecrement = () => {
      this.setState((prevState) => ({
        count: prevState.count - 1
      }));
    };
  
    render() {
      const { count } = this.state;
  
      return (
        <div className="counter">
          <h1 className="price-count">{count}</h1>
          <Button className="increment-decrement-button">+</Button>
          <Button className="increment-decrement-button">-</Button>
        </div>
      );
    }
  }

export default IncrementDecrement
