import React from 'react'
import ContactBlock from '../components/ContactBlock'
import './ConnectWithEterna.css';
import ConnectDescription from '../components/ConnectDescription';
import MessageUs from '../components/MessageUs';
import Connect from '../components/Connect';



function ConnectWithEterna() {
    return (
        <div className="contact" id="contact">
            <div className="connect">
            <Connect />
            </div>
            <div className="contact-blocks">
                <div className="contact-block1">
                    <ContactBlock title="Location" body="University Avenue and, Oxford St, Berkeley, CA 94720" />
                </div>
                <div className="contact-block2">
                    <ContactBlock title="Hours" body="Monday - Friday 9:00am - 9:00pm" />
                </div>
                <div className="contact-block3">
                    <ContactBlock title="Contact" body="admin@eternaspace.com" />
                </div>
            </div>
        </div>
    )
}

export default ConnectWithEterna
