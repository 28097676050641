import React from 'react'
import './Reviews.css'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { BsStarFill } from 'react-icons/bs';
import profile1 from '/Users/liwensun/Desktop/eterna/frontend/src/images/prodile1.png'
import profile2 from '/Users/liwensun/Desktop/eterna/frontend/src/images/profile2.png'
import profile3 from '/Users/liwensun/Desktop/eterna/frontend/src/images/profile3.png'

import Figure from 'react-bootstrap/Figure';

function Reviews() {
    return (
        <div>
            <div className="review-title">
            <h1 className="detailedpage-titles">Reviews</h1>
            <Button className="review-button">Write a review</Button>
            </div>
            <Card className="marketplace-card">
                <Card.Body>
                    <div className="one-review">
                        <Figure className="marketplace-profile">
                            <Figure.Image
                                width={50}
                                height={800}
                                alt="171x180"
                                src={profile1}
                            />
                        </Figure>
                        <div>
                            <Card.Text className="comment-text">
                                "Thank you to the staff that handled my husband's service."
                            </Card.Text>
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="marketplace-card">
                <Card.Body>
                    <div className="one-review">
                        <Figure className="marketplace-profile">
                            <Figure.Image
                                width={50}
                                height={800}
                                alt="171x180"
                                src={profile2}
                            />
                        </Figure>
                        <div>
                            <Card.Text className="comment-text">
                                "Others walk or run at the memorial park, the place is large enough to do so."
                            </Card.Text>
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className="marketplace-card">
                <Card.Body>
                    <div className="one-review">
                        <Figure className="marketplace-profile">
                            <Figure.Image
                                width={50}
                                height={800}
                                alt="171x180"
                                src={profile3}
                            />
                        </Figure>
                        <div>
                            <Card.Text className="comment-text">
                                "That was the year we buried Grandfather "Papa Arch" in our Family Plot."
                            </Card.Text>
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                            <BsStarFill color="gold" />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <hr className="forest-lawn-line"/>

        </div>
    )
}

export default Reviews
