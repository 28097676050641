import React from 'react'
import './RationaleBlock.css'
import Card from 'react-bootstrap/Card';

function RationaleBlock(props) {
    return (
        <div className="rationale-block">
            <Card className="personalization-card" style={{ width: '30rem' }}>
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>{props.content}</Card.Text>
                </Card.Body>
            </Card>
         
        </div>
    )
}

export default RationaleBlock
