import React from 'react'
import TotalPrice from './TotalPrice'
import RationaleBlock from './RationaleBlock'
import './TotalPriceAndRationale.css'

function TotalPriceAndRationale() {
  return (
    <div >
        <div className="total-price-and-rationale">
       <TotalPrice className="total-price-block" price="1,190" />
             
        <RationaleBlock className="rationale-block" title="Rationale" content="Budget-sensitive 
        customers should choose a direct cremation company like Tulip 
        because it offers a direct cremation package at an affordable 
        price, including minimum services of funeral director, staff and 
        basic facilities (e.g., collection and refrigeration of the 
            decedent, cremation, plastic urn,  necessary permits, and 
            mailing of the remains within US)"/>
           
           </div>
    </div>
  )
}

export default TotalPriceAndRationale
