import React from 'react'
import MessageUs from './MessageUs'
import ConnectDescription from './ConnectDescription'
import './Connect.css'

function Connect() {
    return (
        <div>
            <div className="connect-container">
                    <ConnectDescription title="Connect With Eterna"
                        body="inform individuals about our seamless end-of-life planning solutions and our commitment
            to simplifying the process. Remember to select a suitable method for sharing inquiries."/>
                <MessageUs />
            </div>

        </div>
    )
}

export default Connect
