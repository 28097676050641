import React from 'react'
import './Sidebar.css';
import {
    CDBSidebar,
    CDBSidebarContent,
    CDBSidebarFooter,
    CDBSidebarHeader,
    CDBSidebarMenu,
    CDBSidebarMenuItem,
    CDBBadge
} from 'cdbreact';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1 } from '@fortawesome/free-solid-svg-icons';
import { mdiNumeric1 } from '@mdi/js';


function Sidebar({ toggleStep }) {
    return (
        <div>
            <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
                <CDBSidebar textColor="black" backgroundColor="#f4e6bd" maxWidth="270px" margin-bottom="0">
                <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>} className="sidebar-header">
                        <a className="text-decoration-none" style={{ color: 'inherit' }}>
                            Step-by-Step Guide
                        </a>
                    </CDBSidebarHeader>
                    <CDBSidebarContent>
                        <CDBSidebarMenu>
                            <CDBSidebarMenuItem icon="tasks" iconSize="lg">

                                <button className="sidebar-button0" onClick={() => toggleStep(0)}>About</button>
                            </CDBSidebarMenuItem>
                            <CDBSidebarMenuItem icon="check" iconSize="lg">
                                <button className="sidebar-button1" onClick={() => toggleStep(1)}>Handle These First</button>
                            </CDBSidebarMenuItem>
                            <CDBSidebarMenuItem icon="check"iconSize="lg">
                                <button className="sidebar-button2" onClick={() => toggleStep(2)}>Handle These Second</button>
                            </CDBSidebarMenuItem>
                            <CDBSidebarMenuItem icon="check" iconSize="lg">
                                <button className="sidebar-button3" onClick={() => toggleStep(3)}>Handle These Third</button>
                            </CDBSidebarMenuItem>
                            <CDBSidebarMenuItem icon="check" iconSize="lg">
                                <button className="sidebar-button4" onClick={() => toggleStep(4)}>Handle These Fourth</button>
                            </CDBSidebarMenuItem>
                        </CDBSidebarMenu>
                    </CDBSidebarContent>


                </CDBSidebar>
            </div>

        </div>
    )
}

export default Sidebar
