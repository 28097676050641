import React from 'react'
import './HeaderNavbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Figure from 'react-bootstrap/Figure';
import { LinkContainer } from 'react-router-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { HashLink } from 'react-router-hash-link';

function HeaderNavbar() {
    return (
        <div className="header-navbar navbar-custom">
            <Navbar expand="lg" className="navbar-custom">
                <Navbar.Brand className="logo " href="#home">
                    <HashLink to="/#home">Eterna</HashLink>
                </Navbar.Brand>
                <Container className="nav-links">
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav ">
                        <Nav className="ms-auto ">
                            <LinkContainer to="/step-by-step">
                                <Nav.Link>Step by Step Guide</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/marketplace">
                                <Nav.Link>Marketplace</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/personalization">
                                <Nav.Link>Personalization</Nav.Link>
                            </LinkContainer>
                            <HashLink className="nav-link" to="/#about">About</HashLink>
                            <HashLink className="nav-link" to="/#contact">Contact</HashLink>
                        </Nav>
                        <div className="auth-buttons">
                            <Button  className="button1">Login</Button>
                            <Button className="button2">Sign Up</Button>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </div>
    )
}

export default HeaderNavbar
