import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import './StepByStepOne.css'
import SavePlan from './SavePlan';


function StepByStepTwo(props) {
  const allEventKeys = ["4", "5", "6"];
  const [activeKeys, setActiveKeys] = useState(allEventKeys);
  return (
    <div className={props.className}>
      <div className="steps-title">
        <h1 className="step-by-step-title">Handle These Second</h1>
        <SavePlan />
      </div>
      <Accordion className="steps-accordian" defaultActiveKey={activeKeys} alwaysOpen={true}>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Outreach and Informing Others</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify deceased's employer`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify loved ones and the larger community`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Plan the Funeral</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Review ways to fund a funeral`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Choose a final disposition and funeral services`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Cremation package (incl. funeral director, transportation of body,
                    crematory fees, return ashes)`}
                    className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Direct Burial Service (funeral director, transportation of body,
                    cemetery)`}
                    className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Choose casket/urn/shroud`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Determine the ceremony (e.g., funeral, memorial, graveside ceremony)`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>Claim Benefits</Accordion.Header>
          <Accordion.Body>
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Notify veterans affairs`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`File life insurance claim and employee benefits`}
              className="steps-checkbox"
            />
            <Form.Check type={'checkbox'} id={`default-checkbox`}
              label={`Claim social security lump-sum payment`}
              className="steps-checkbox"
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default StepByStepTwo
