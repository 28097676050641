import React from 'react'
import PersonalizationEight from '../../components/Personalization/PersonalizationEight'
import StoneFlowers from '../../images/stone-flowers.png';
import HeaderNavbar from '../../components/HeaderNavbar';
import './PersonalizationPG8.css'
import Figure from 'react-bootstrap/Figure';


function PersonalizationPG8() {
  return (
    <div>
      <HeaderNavbar/>
      <div className="pers-pg1-container">
        <Figure className="flower-background">
          <Figure.Image
            margin={0}
            alt="171x180"
            src={StoneFlowers}
          />
        </Figure>
        <div className="pers-1">
          <PersonalizationEight />
        </div>
      </div>
    </div>
  )
}

export default PersonalizationPG8
