import './Home.css';
import React from 'react';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import Figure from 'react-bootstrap/Figure';
import homepage from './images/canva-homepage.png';
import { LinkContainer } from 'react-router-bootstrap';
import Services from './components/Services';
import EssentialServices from './pages/EssentialServices';
import ConnectWithEterna from './pages/ConnectWithEterna';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import HeaderNavbar from './components/HeaderNavbar';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Reviews from './pages/Reviews';
import Nav from 'react-bootstrap/Nav';



function Home() {
  return (
    <div className="Home">
      <HeaderNavbar />
      <Figure className="home-banner">
        <Figure.Image
          alt="171x180"
          src={homepage}
        />
        <div className="banner-text">Enpowering Families With End-of-Life Planning Solutions</div>
        <Button className="homepage-button" href="/step-by-step">Step by Step <ArrowForwardIcon /></Button>
      </Figure>
      <EssentialServices />
      <Reviews />
      <div>
        <ConnectWithEterna className="connect-with-eterna" />
      </div>
    </div>
  );
}

export default Home;
