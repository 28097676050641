import React from 'react'
import IncrementDecrement from './IncrementDecrement'
import './OtherConsiderations.css'

function OtherConsiderations() {
  return (
    <div className="other-considerations">
      <p>You may also consider:</p>
      <div className="personalization-addtional-considerations">
        <p className="optimize-budget-other-choice">1. Funeral home: Forest Lawn</p>
        <IncrementDecrement intialCount="$13,425"/>
      </div>
      <div className="personalization-addtional-considerations">
        <p>2. Alternative Cremation Urn: Amazon</p>
        <IncrementDecrement intialCount="$30"/>
      </div>
      <div className="personalization-addtional-considerations">
        <p>1. Funeral home: Forest Lawn</p>
        <IncrementDecrement intialCount="$25"/>
      </div>

    </div>
  )
}

export default OtherConsiderations
