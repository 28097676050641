import React from 'react'
import IncrementDecrement from './IncrementDecrement'
import './TotalPrice.css'

function TotalPrice(props) {
    return (
        <div className="total-price">
            <div className="personalization-price-title">
                <p className="total-price">Total Price</p>
                <p>${props.price}</p>
            </div>
            <p>Recommended Providers:</p>
            <div className="personalization-name-and-price">
                <p className="optimize-budget-first-choice">1. Direct cremation: Tulip Cremation</p>
                <IncrementDecrement className="incrementer-decrementer" intialCount="$1,190"/>
            </div>
            <p>Alternatives</p>
        </div>
    )
}

export default TotalPrice
