import React from 'react'
import PersonalizationThree from '../../components/Personalization/PersonalizationThree'
import Figure from 'react-bootstrap/Figure';
import flowerBackground from '../../images/flowers.png';
import HeaderNavbar from '../../components/HeaderNavbar';
import './PersonalizationPG2.css'

function PersonalizationPG3() {
  return (
    <div>
      <HeaderNavbar/>
      <div className="pers-pg1-container">
        <Figure className="flower-background">
          <Figure.Image
            width={500}
            height={800}
            margin={0}
            alt="171x180"
            src={flowerBackground}
          />
        </Figure>
        <div className="pers-1">
          <PersonalizationThree />
        </div>
      </div>
    </div>
  )
}

export default PersonalizationPG3
