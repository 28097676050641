import React from 'react'
import PersonalizationSeven from '../../components/Personalization/PersonalizationSeven'
import flowerBackground from '../../images/flowers.png';
import HeaderNavbar from '../../components/HeaderNavbar';
import './PersonalizationPG2.css'
import Figure from 'react-bootstrap/Figure';



function PersonalizationPG7() {
  return (
    <div>
      <HeaderNavbar/>
      <div className="pers-pg1-container">
        <Figure className="flower-background">
          <Figure.Image
            width={500}
            height={800}
            margin={0}
            alt="171x180"
            src={flowerBackground}
          />
        </Figure>
        <div className="pers-1">
          <PersonalizationSeven />
        </div>
      </div>
    </div>
  )
}

export default PersonalizationPG7
