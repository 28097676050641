import React from 'react'
import DetailedPageAbout from '../../components/MarketPlace/DetailedPageAbout'
import DetailedPricing from '../../components/MarketPlace/DetailedPricing'
import Photos from '../../components/MarketPlace/Photos'
import './DetailedLeft.css'
import Reviews from '../../components/MarketPlace/Reviews'
import Questions from '../../components/MarketPlace/Questions'

function DetailedLeft() {
  return (
    <div className="detailed-left">
      <DetailedPageAbout/>
      <DetailedPricing/>
      <Photos/>
      <Reviews/>
      <Questions/>
    </div>
  )
}

export default DetailedLeft
