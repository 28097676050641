import React from 'react'
import './MessageUs.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function MessageUs() {
    return (
        <div className="message-us">
            <Form>
                <Form.Group controlId="formBasicEmail">
                    <div className="email-title-container">
                    <Form.Label>Email (required)</Form.Label>
                    </div>
                    <Form.Control className="email" type="email"/>
                </Form.Group>

                <Form.Group>
                    <div className="message-title-container">
                    <Form.Label className="message-title">Message (required)</Form.Label>
                    </div>
                    <Form.Control className="message" as="textarea" rows={3}/>
                </Form.Group>
                <div className="send-button-container">
                <Button className="send-button" variant="dark" type="submit">
                    Send
                </Button>
                </div>
            </Form>

        </div>
    )
}

export default MessageUs
