import React from 'react'
import PersonalizationTwo from '../../components/Personalization/PersonalizationTwo'
import Figure from 'react-bootstrap/Figure';
import flowerBackground from '../../images/flowers.png';
import HeaderNavbar from '../../components/HeaderNavbar';
import './PersonalizationPG2.css'

function PersonalizationPG2() { 
  return (
    <div>
      <HeaderNavbar/>
      <div className="pers-pg1-container">
        <Figure className="flower-background">
          <Figure.Image
            width={500}
            height={800}
            margin={0}
            alt="171x180"
            src={flowerBackground}
          />
        </Figure>
        <div className="pers-1">
          <PersonalizationTwo />
        </div>
      </div>
    </div>
  )
} 

export default PersonalizationPG2
